<template>
  <div class="all-list">
    <van-nav-bar title="故障维修" left-text="返回" fixed left-arrow @click-left="goBack" />
    <van-search class="search" placeholder="请输入搜索关键词" @search="onSearch" />
    <van-search class="search" placeholder="请输入搜索关键词" @search="onSearch" />
    <van-tabs v-model:active="activeName" class="list-tab" @click="handleClick">
      <van-tab name="99" :title="'全部' + '(' + total + ')' "></van-tab>
      <van-tab name="1" :title="'待维修' + '(' + todo + ')'"></van-tab>
      <van-tab name="2" :title="'完成' + '(' + done + ')'"></van-tab>
      <van-tab name="3" :title="'维修中' + '(' + doing + ')'"></van-tab>
      <van-tab name="4" :title="'待班长审核' + '(' + to_monitor + ')'"></van-tab>
      <van-tab name="5" :title="'待工程师审核' + '(' + to_engineer + ')'"></van-tab>
      <van-tab name="6" :title="'待确认' + '(' + to_done + ')'"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="isRefresh" head-height="80" distance="80" @refresh="onRefresh">
      <van-list class="index-listPlus" v-model="loading" :finished="isFinished" :finished-text="finishedText" @load="loadMore">
      <van-cell class="bq-list" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <van-swipe-cell>
        <van-row type="flex" class="tag">
          <div class="asset-title">
            <div style="width: 78%;margin-left: 2px">{{ item.equipment }}</div>
            <div v-html="item.status"></div>
          </div>
        </van-row>
          <van-divider />
          <div style="padding-top: 8px">
            <van-row type="flex" class="details">
              <van-col>是否停机：{{ item.halt}}</van-col>
            </van-row>
            <van-row type="flex" class="details">
              <van-col>编码：{{ item.coding }}</van-col>
            </van-row>
            <van-row type="flex" class="details">
              <van-col>开始执行时间：{{ item.arrange_time }}</van-col>
            </van-row>
            <van-row type="flex" class="details">
              <van-col v-if="item.complete_time">完成时间：{{ item.complete_time }}</van-col>
              <van-col v-else>维修未完成</van-col>
            </van-row>
          </div>
        </van-swipe-cell>
      </van-cell>
    </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import API from '@/api/workorder/cm'
export default {
  name: "projectPage",
  inject: ['goUrl', 'reload'],
  data() {
    return {
      active: 0,
      list: [],
      value:'',
      loading: false,
      isFinished: false,
      isRefresh: false,
      finishedText:'加载完成',
      isLoading: false,
      total: null,
      todo: null,
      done: null,
      to_monitor: null,
      to_engineer: null,
      doing: null,
      to_done: null,
      countAlready: false,
      query: {
        status: null,
        page: 0,
        limit: 5
      }
    }
  },
  created() {
  },
  methods: {
    index() {
      this.isLoading = true
      if (this.isRefresh) {
        this.list = []
        this.query.page = 0
        this.isRefresh = false
        this.isFinished = false
      }
      this.toTop()
      API.getList(this.query).then(res => {
        this.list.push(...res.data.list.data)
        if (this.list.length >= res.data.list.total) {
          this.isFinished = true
        }
        if(!this.countAlready){
          this.total = res.data.total
          this.todo = res.data.todo
          this.done = res.data.done
          this.to_monitor = res.data.to_monitor
          this.to_engineer = res.data.to_engineer
          this.doing =res.data.doing
          this.to_done =res.data.to_done
          this.countAlready = true
        }
      }).finally(() =>{
        this.loading = false
        this.isLoading = false
      })
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    loadMore() {
      if(!this.isLoading){
        this.query.page += 1
        this.index()
      }
    },
    async deleteBtn(id) {
      const res = await API.delete(id)
        if (res.data.error_code !== 1) {
          this.$toast(res.data.message)
        } else {
          this.$toast.success('删除成功')
          this.reload()
        }
    },
    goBack() {
      this.goUrl('/index')
    },
    handleClick(name) {
      if(name === '99'){
        this.query.status = null
        this.list = []
        this.query.page = 0
        this.index()
      } else if (name === '666'){
        this.query.status = null
        this.list = []
      } else {
        this.query.status = parseInt(name)
        this.list = []
        this.query.page = 0
        this.index()
      }
    },
    onSearch() {
      this.query.page = 1
      this.list = []
      this.index()
    },
    goDetail(id){
      const url = `/workorder/cm/edit/${id}`
      this.goUrl(url)
    },
    onRefresh() {
      this.reload()
      this.index()
    }
  }
}
</script>

<style scoped>
  .asset-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
</style>
